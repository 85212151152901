(function($){
    $(document).ready(function() {
        var MainSlider = function(elem,sliderPaprms) {
            var self = this;
            this.wrapper = elem;
            this.sliderParams = sliderPaprms || {};
            this.banners = this.wrapper.find('.banner_wrapper');
            this._preventEmptyLinks = function() {
                self.banners.each(function(key,value) {
                    var link = $(value).find('a');
                    if(link.attr('href').length < 2) {
                        link.css('pointer-events','none');
                    }
                });
            };
            this._sliderInit = function() {
                self.wrapper.slick(this.sliderParams);
            };
            this.init = function() {
                self._preventEmptyLinks();
                self._sliderInit();
            };
            this.init();
        };

        new MainSlider($(".header-slider-wrapper"),{
            autoplay:true,
            autoplaySpeed:5000,
            dots:true,
            fade:true,
            prevArrow:'<button type="button" class="slick-prev"></button>',
            nextArrow:'<button type="button" class="slick-next"></button>',
            speed:500,
            pauseOnDotsHover:true,
            adaptiveHeight:true,
            responsive: [{
                breakpoint: 768,
                settings: {
                    dots:false,
                    arrows:false
                }
            }]
        });
    });
})(jQuery);
