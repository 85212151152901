
(function($){
    $(document).ready(function() {

        var FancyInit = function(wrapper,fancyClass,rel,removeBanners,fancyOptions) {
            var self = this;
            this.options = {
                fancyClass: fancyClass || 'fancybox',
                rel: rel || 'gallery1',
                fancyOptions: fancyOptions || {
                    openEffect	: 'none',
                    closeEffect	: 'none'
                }
            };
            this.fancyWrapper = wrapper;
            this.imagesArr = wrapper.find('img');
            this.imagesLinks = [];
            this._collectImageLinks = function() {
                self.imagesArr.each(function(key,value) {
                    var src = $(value).attr('src');
                    var rightClass = '';
                    if((key+1)%3===0) {rightClass = 'right-col';}
                    self.imagesLinks.push(src);
                    self.fancyWrapper.append('<a class="' + self.options.fancyClass + ' ' +  rightClass +'" rel="' + self.options.rel + '" href="' + src + '"><img src="'+ src + '" alt="image"/></a>');
                });
            };
            this._removeBanners = function() {
                self.fancyWrapper.find('.banner_wrapper').remove();
            };
            this._initFancy = function() {
                $("." + fancyClass).fancybox(self.options.fancyOptions);
            };
            this.init = function() {
                self._collectImageLinks();
                if(removeBanners) {
                    self._removeBanners();
                }
                self._initFancy();
            };
        };

        var homeFancyInit = new FancyInit($('.js-fancy-wrapper'),'fancybox','gallery1',true,{
            openEffect	: 'none',
            closeEffect	: 'none'
        });

        homeFancyInit.init();
    });
})(jQuery);
