(function($){
    $(document).ready(function() {

        // store the slider in a local variable
        var $window = $(window),
            flexslider = { vars:{} };

        // tiny helper function to add breakpoints
        function getGridSize() {
            return (window.innerWidth < 600) ? 4 :
                (window.innerWidth < 900) ? 4 : 4;
        }

        // check grid size on resize event
        $window.resize(function() {
            var gridSize = getGridSize();
            flexslider.vars.minItems = gridSize;
            flexslider.vars.maxItems = gridSize;
        });

        var GalleryInit = function(wrapper,removeBanners,sliderId,carouselId,flexSliderClass,sliderOptions,paginationOptions) {
            var self = this;
            this.options = {
                sliderOptions: sliderOptions || {

                },
                paginationOptions:paginationOptions || {

                }
            };
            this.sliderWrapper = wrapper;
            this.banners = wrapper.find('.banner_wrapper');
            this.slides = [];
            this._getOrder = function(str,key) {
                var orderNumber = 0;
                if (str.indexOf('order-') !== -1) {
                    var m  = str.match(/order-(\d+) /);
                    orderNumber = m[1];
                } else {
                    orderNumber = key;
                }
                return parseInt(orderNumber);
            };
            this._slidesObjectCreation = function() {
                self.banners.each(function(key,value) {
                    self.slides.push({
                        imgLink:$(value).find('img').attr('src'),
                        captionText:$(value).find('.banner_caption_inner').text(),
                        order:self._getOrder($(value).find('.banner').attr('class'),key)
                    });
                });
            };
            this._sortBanners = function() {
                self.slides.sort(function(a, b) {
                    return a.order - b.order;
                });
            };
            this._collectImageLinks = function() {
                self.sliderWrapper.append('<ul class="slides"></ul>');
                $.each(self.slides,function(key,value){
                    if(value.captionText) {
                        self.sliderWrapper.find('ul.slides').append('<li><img src="' + value.imgLink + '" alt=""><p class="slide-caption">' + value.captionText + '</p></li>');
                    } else {
                        self.sliderWrapper.find('ul.slides').append('<li><img src="' + value.imgLink + '" alt=""></li>');
                    }
                });
            };
            this._removeBanners = function() {
                self.sliderWrapper.find('.banner_wrapper').remove();
            };
            this._addPagination = function() {
                self.sliderWrapper.after('<div id="' + carouselId + '" class="slider-pagination ' + flexSliderClass + '"><ul class="slides"></ul></div>');
                $.each(self.slides,function(key,value) {
                    $('#'+carouselId).find('ul.slides').append('<li style="background-image: url(' + value.imgLink + ')"></li>');
                });
            };
            this._initSlider = function() {
                $('#'+sliderId).flexslider({
                    animation: 'slide',
                    controlNav: false,
                    animationLoop: false,
                    slideshow: false,
                    sync: '#'+carouselId,
                    smoothHeight:true
                });
            };
            this._initPagination = function() {
                $('#'+carouselId).flexslider({
                    animation: "slide",
                    controlNav: false,
                    animationLoop: false,
                    slideshow: false,
                    itemWidth: 190,
                    itemMargin: 30,
                    asNavFor: '#'+sliderId,
                    minItems: getGridSize(), // use function to pull in initial value
                    maxItems: getGridSize() // use function to pull in initial value
                });
            };
            this.init = function() {
                self.sliderWrapper.attr('id',sliderId).addClass(flexSliderClass);
                self._slidesObjectCreation();
                self._sortBanners();
                self._collectImageLinks();
                if(removeBanners) {
                    self._removeBanners();
                }
                self._addPagination();
                self._initSlider();
                self._initPagination();
            };
            this.init();
        };

        $('.gallery-slider-wrapper').each(function(key,value) {
            new GalleryInit($(value),true,'slider-'+key,'carousel-'+key,'flexslider',{},{});
        });
    });
})(jQuery);
