(function($){
    $(document).ready(function() {
        $('.js-menu-button').click(function(e) {
            e.preventDefault();
            var $headerNavigationWrapper = $('.header-navigation.mobile-view');
            if($headerNavigationWrapper.hasClass('open')) {
                $headerNavigationWrapper.removeClass('open');
                $headerNavigationWrapper.stop().slideUp('medium');
                $(this).find('i').removeClass('fa-times').addClass('fa-bars');
            } else {
                $headerNavigationWrapper.addClass('open');
                $headerNavigationWrapper.stop().slideDown('medium');
                $(this).find('i').removeClass('fa-bars').addClass('fa-times');
            }
        });
    });
})(jQuery);
