(function($){
    $(document).ready(function() {
        $('.js-language-select').click(function(e) {
            e.preventDefault();
            e.stopPropagation();
            var $wrapper = $(this).parent();
            var $list = $wrapper.find('.languages-list');
            if($list.hasClass('open')) {
                $list.removeClass('open');
            } else {
                $list.addClass('open');
            }
        });

        $('body').click(function() {
            $('.languages-list').removeClass('open');
        });
    });
})(jQuery);
