(function($){
    $(document).ready(function() {

        var ContactFormDom = function(form) {
            var self = this;
            this.$form = form;
            this._addClassesToParagraphs = function() {
                self.$form.find('p').each(function(key,value) {
                    var $paragraph = $(value);
                    var $field = $paragraph.find('input[type="text"],input[type="email"],input[type="submit"],textarea');
                    $paragraph.addClass('form-item').addClass(($field.attr('name')) ? $field.attr('name')+'-item' : 'no-name-item');
                });
            };
            this._addLinkClassToButton = function() {
                self.$form.find('p').each(function(key,value) {
                    var $paragraph = $(value);
                    var $field = $paragraph.find('input[type="submit"]');
                    $field.addClass('link');
                });
            };
            this._removeSizeAttributes = function() {
                self.$form.find('p').each(function(key,value) {
                    var $paragraph = $(value);
                    var $field = $paragraph.find('input[type="text"],input[type="email"]');
                    $field.attr('size','');
                });
            };
            this._wrapLabel = function() {
                self.$form.find('p').each(function(key,value) {
                    var $paragraph = $(value);
                    var $label = $paragraph.find('label');
                    var labelText = $label.text();
                    $label.prepend('<span class="label-text">' + labelText + '</span>');
                    $label.contents().filter(function () {
                        return this.nodeType === 3;
                    }).remove();
                });
            };
            this._setInputWidth = function() {
                self.$form.find('p').each(function(key,value) {
                    var $paragraph = $(value);
                    var paragraphWidth = $paragraph.find('label').width();
                    var labelTextWidth = $paragraph.find('.label-text').width();
                    $paragraph.find('input[type="text"],input[type="email"]').css('width', (paragraphWidth-labelTextWidth-8)+'px');

                });
            };
            this.init = function() {
                self._addClassesToParagraphs();
                self._removeSizeAttributes();
                self._wrapLabel();
                self._addLinkClassToButton();
                self._setInputWidth();
                $(window).resize(function() {
                    self._setInputWidth();
                });
            };
            this.init();
        };

        new ContactFormDom($('.js-contact-form form'));

        $('.your-name-item,.your-email-item').wrapAll('<div class="input-fields-wrapper"></div>');

    });
})(jQuery);